<template lang="html">
  <section class="news">
    <hero-global
      img="/img/hero/actualidad.svg"
      title="Actualidad"
      subtitle="Actualidad"
    />
    <section class="menu-anclas container">
      <router-link
        :class="[
          this.$route.hash == '#noticias'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        to="#noticias"
        @click="scrollSection('noticias')"
        title="Ir a Noticias"
        >Noticias</router-link
      >
      <router-link
        :class="[
          this.$route.hash == '#novedades'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        to="#novedades"
        @click="scrollSection('novedades')"
        title="Ir a Novedades"
        >Novedades</router-link
      >
      <router-link
        to="#agenda"
        @click="scrollSection('agenda')"
        :class="[
          this.$route.hash == '#agenda'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        title="Ir a Agenda"
        >Agenda</router-link
      >
      <router-link
        to="#imago-cermi"
        @click="scrollSection('imago-cermi')"
        :class="[
          this.$route.hash == '#imago-cermi'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        title="Ir a Imago Cermi"
        >Imago Cermi</router-link
      >
      <router-link
        to="#galeria"
        @click="scrollSection('galeria')"
        :class="[
          this.$route.hash == '#galeria'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        title="Ir a Imágenes"
        >Imágenes</router-link
      >
      <router-link
        to="#exposiciones"
        @click="scrollSection('exposiciones')"
        :class="[
          this.$route.hash == '#exposiciones'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        title="Ir a Exposiciones"
        >Exposiciones</router-link
      >
      <router-link
        to="#contacto-prensa"
        @click="scrollSection('contacto-prensa')"
        :class="[
          this.$route.hash == '#contacto-prensa'
            ? 'btn btn-tertiary active-class'
            : 'btn btn-tertiary ',
        ]"
        title="Ir a Contacto de prensa"
        >Contacto de prensa</router-link
      >
      <router-link
        to="/hemeroteca"
        class="btn btn-tertiary"
        title="Ir a hemeroteca"
        >Hemeroteca</router-link
      >
    </section>
    <div class="container">
      <select
        name="menu-anclas-responsive"
        id="menu-anclas-responsive"
        class="select-anclas-responsive"
        v-model="menuAnclas"
        :activeMenuTop="menuTopAnclas"
      >
        <option value="noticias" title="Ir a Noticias">Noticias</option>
        <option value="novedades" title="Ir a Novedades">Novedades</option>
        <option value="agenda" title="Ir a Agenda">Agenda</option>
        <option value="imago-cermi" title="Ir a Imago Cermi">Imago Cermi</option>
        <option value="galeria" title="Ir a Imágenes">Imágenes</option>
        <option value="exposiciones" title="Ir a Exposiciones">Exposiciones</option>
        <option value="contacto-prensa" title="Ir a Contacto de prensa">Contacto de prensa</option>
      </select>
    </div>
    <section id="noticias" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Noticias CERMI">
          Noticias
        </h2>
      </div>
      <section class="grid-3" v-if="store.getEntries(this.page)">
        <template
          v-for="(notice, index) in Object.values(
            store.getEntries(this.page)
          ).slice(0, 3)"
          :key="index"
        >
          <newVerticalImg
            :dataContent="notice"
            typeContent="entry"
          ></newVerticalImg>
        </template>
      </section>
      <div class="column-center m-bottom-30-px">
        <router-link
          to="/hemeroteca"
          class="btn btn-tertiary"
          title="Ir a página de Hemeroteca" 
          alt="Ir a página de Hemeroteca"
          >Ver más</router-link
        >
      </div>
    </section>
    <section id="novedades">
      <div class="column-center container">
        <h2
          class="title-grand title__double-center"
          data-text="Últimas novedades"
        >
          Novedades
        </h2>
      </div>
      <section v-if="store.getNovelties(this.page)" class="container">
        <template
          v-for="(novelti, index) in Object.values(
            store.getNovelties(this.page)
          ).slice(0, 4)"
          :key="index"
        >
          <noveltie :dataContent="novelti"></noveltie>
        </template>
        <div class="justify-content-center d-flex w-100">
          <router-link
            :to="{ name: 'novedades' }"
            title="Ir a página de novedades" 
            alt="Ir a página de novedades"
            class="btn-white"
            >Más novedades</router-link
          >
        </div>
      </section>
    </section>
    <section id="agenda" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Agenda CERMI">
          Agenda
        </h2>
      </div>
      <section class="grid-3 grid-3 jcc d-flex w-100">
        <template v-if="store.getEvents(this.year, this.page)">
          <template
            v-for="(event, index) in Object.values(
              store.getEvents(this.year, this.page)
            ).slice(0, 2)"
            :key="index"
          >
            <eventViewSimple
              :dataContent="event"
              typeContent="event"
            ></eventViewSimple>
          </template>
        </template>
        <section id="agenda-presidencia" class="grid">
          <div class="column-center container">
            <p class="title-decoration-bottom">
              Agenda semanal de la <br />Presidencia del CERMI Estatal
            </p>
            <p class="subtitle m-bottom-2-rem">
              Semana del {{ firstDayWeek }} al {{ lastDayWeek }}
            </p>
            <router-link
              :to="{ name: 'president-agend' }"
              title="Ver Agenda de la Presidencia"
              alt="Ver Agenda de la Presidencia"
              class="btn btn-transparent"
              >Ver Agenda de la Presidencia</router-link
            >
          </div>
        </section>
      </section>

      <div class="column-center m-bottom-30-px">
        <router-link
          :to="{ name: 'events' }"
          class="btn-white"
          title="Ir a página de eventos" 
          alt="Ir a página de eventos"
          >Ver más</router-link
        >
      </div>
    </section>

    <section id="imago-cermi" class="container">
      <div class="column-center">
        <h2 class="title-grand title__double-center" data-text="Galería">
          Imago CERMI
        </h2>
      </div>

      <agile
        v-if="store.getVideos(this.yearVideos, this.page)"
        :options="myOptions"
        ref="carousel"
        class="slider-galeria container"
      >
        <router-link
          :to="{
            name: 'page-entry',
            params: {
              typeContent: 'multimedia',
              slug: media.slug,
            },
          }"
          v-for="(media, index) in Object.values(
            store.getVideos(this.yearVideos, this.page)
          )"
          :key="index"
          title="Enlace"
          class="content-new-galeria"
        >
          <span v-if="media.url" src="/img/img-test.png" class="icon"></span>
          <img
            v-if="media.image.url"
            :src="media.image.url"
            :alt="media.description"
          />
        </router-link>
      </agile>

      <div class="justify-content-center d-flex w-100">
        <router-link
          :to="{
            name: 'multimediaImago',
            params: {
              Nyear: yearVideos,
            },
          }"
          class="btn-white"
          title="Ir a Ver más entradas" 
          alt="Ir a Ver más entradas"
          >Ver más</router-link
        >
      </div>
    </section>
    <section id="galeria" class="container">
      <div class="column-center">
        <h2
          class="title-grand title__double-center"
          data-text="Galería de imágenes"
        >
          Galería
        </h2>
      </div>

      <agile
        v-if="store.getImages(this.yearImages, this.page)"
        :options="myOptions"
        ref="carousel"
        class="slider-galeria container"
      >
        <router-link
          :to="{
            name: 'page-entry',
            params: {
              typeContent: 'multimedia',
              slug: img.slug,
            },
          }"
          v-for="(img, index) in Object.values(
            store.getImages(this.yearImages, this.page)
          )"
          :key="index"
          title="Enlace"
          class="content-new-galeria"
        >
          <span v-if="img.url" src="/img/img-test.png" class="icon"></span>
          <img
            v-if="img.image.url"
            :src="img.image.url"
            :alt="img.description"
          />
        </router-link>
      </agile>

      <div class="justify-content-center d-flex w-100">
        <router-link
          :to="{
            name: 'multimediaImagenes',
            params: {
              Nyear: yearImages,
            },
          }"
          class="btn-white"
          title="Ir a Ver más entradas" 
          alt="Ir a Ver más entradas"
          >Ver más</router-link
        >
      </div>
    </section>
    <section id="exposiciones">
      <div class="grid-3 container">
        <div class="column-center w-100">
          <h2
            class="title-grand title__double-center"
            data-text="Sala de exposiciones"
          >
            Exposiciones
          </h2>
        </div>
        <expo-room
          v-for="(exposition, index) in Object.values(store.expositions).slice(
            0,
            3
          )"
          :key="index"
          class="grid"
          :dataContent="exposition"
        ></expo-room>
      </div>
      <div class="text-center m-top-20-px">
        <router-link
          :to="{ name: 'exposiciones' }"
          title="Ir a página de exposiciones" 
          alt="Ir a página de exposiciones"
          class="btn-white"
          >Ver más</router-link
        >
      </div>
    </section>
    <section id="contacto-prensa" class="container">
      <div class="column-center">
        <h2
          class="title-grand title__double-center"
          data-text="Contacto de prensa"
        >
          Contacto
        </h2>
      </div>
      <div class="container-forms-colors">
        <div class="blue-container">
          <p>
            Los medios de comunicación y periodistas que deseen recabar
            informaciones del CERMI, deben ponerse en contacto con:
          </p>
        </div>
        <div class="white-container">
          <p class="title">Ainhoa Bueno</p>
          <span>Responsable de Comunicación del CERMI</span>
          <a
            href="mailto:   prensa@cermi.es"
            class="category-email item-list"
            title="Mandar un emali a   prensa@cermi.es"
          >
            prensa@cermi.es</a
          >
          <a
            href="mailto: abueno@ilunion.com"
            class="category-email item-list"
            title="Mandar un emali a abueno@ilunion.com"
            >abueno@ilunion.com</a
          >
        </div>
      </div>
    </section>
    <bannerSemanal></bannerSemanal>
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script lang="js">
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
import newVerticalImg from "@/components/Card/NewVerticalImg";
import eventViewSimple from "@/components/Card/EventViewSimple";
import bannerSemanal from "@/components/Layout/bannerCermiSemanal";
import { VueAgile } from "vue-agile";
import ExpoRoom from "@/components/Home/ExpoRoom";
import { contentStore } from '@/stores/contents';
import moment from 'moment';
import noveltie from "@/components/Card/Noveltie";

export default {
  name: 'news',
  props: ['idDay'],
  components: {
    OtherLinksInterest,
    HeroGlobal,
    newVerticalImg,
    eventViewSimple,
    agile: VueAgile,
    ExpoRoom,
    noveltie,
    bannerSemanal
  },
  setup() {
    const store = contentStore();
    store.loadNews()
    store.loadExpositions()

    return {
      store
    }
  },
  mounted() {
    this.topMenuAnclas()
    this.store.loadVideos({ maxYear: new Date().getFullYear() })
    this.store.loadImages({ maxYear: new Date().getFullYear() })
    moment.updateLocale('es', {
      week: {
        dow: 1, // Monday is the first day of the week.
      }
    })
    this.store.loadEvents(
      {
        dateCurrent: new Date(),
        page: this.page,
      }
    )
    this.store.loadNovelties(
      {
        maxYear: new Date().getFullYear(),
        page: this.page,

      }
    )
    let startDate = moment().startOf('week');
    let endDate = moment().endOf('week');
    this.firstDayWeek = startDate.format('DD/MM/YYYY'),
      this.lastDayWeek = endDate.format('DD/MM/YYYY')
    if (this.$route.hash) {
      let element = this.$route.hash.replace('#', '')
      const offsetTop = document.getElementById(element).offsetTop - 150;
      setTimeout(function () {
        scroll({
          behavior: "smooth",
          top: offsetTop,
        });
      }, 200);
    }
  },
  data() {
    return {
      menuAnclas: 'noticias',
      menuTopAnclas: false,
      firstDayWeek: null,
      lastDayWeek: null,
      year: new Date().getFullYear(),
      // year: 2022,
      page: 1,
      myOptions: {
        responsive: [{
          breakpoint: 900,
          settings: {
            navButtons: false,
            slidesToShow: 4,
            dots: true,
            centerMode: true,
            //unagile: true,
          },
        }, {
          breakpoint: 800,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            navButtons: false,
            slidesToShow: 2,
            dots: true,
            unagile: false,
            autoplay: true,
          },
        }, {
          breakpoint: 0,
          settings: {
            navButtons: false,
            slidesToShow: 1,
            dots: true,
            autoplay: true,
          },
        },],
      },
      showFeatureAll: true,
      showMore: false,
    }
  },
  methods: {
    topMenuAnclas: function () {
      window.onscroll = () => {
        if (window.scrollY > 0) {
          this.menuTopAnclas = true;
        } else {
          this.menuTopAnclas = false
        }
      }
    },
    scrollSection: function (params) {
      if (params) {
        const offsetTop = document.getElementById(params).offsetTop - 150;
        setTimeout(function () {
          scroll({
            behavior: "smooth",
            top: offsetTop,
          });
        }, 200);
      }
    },
  },
  computed: {

    yearImages() {
      let y;
      if (this.store.images.items[new Date().getFullYear()] === undefined) {
        y = 2022
        this.store.loadImages({ maxYear: y })

      } else {
        y = new Date().getFullYear()
        this.store.loadImages({ maxYear: y })

      }
      return y
    },
    yearVideos() {
      let y;

      if (this.store.videos.items[new Date().getFullYear()] === undefined) {
        y = 2022
        this.store.loadVideos({ maxYear: y })

      } else {
        y = new Date().getFullYear()
        this.store.loadVideos({ maxYear: y })

      }
      return y
    },
    yearNovelties() {
      let y;

      if (this.store.novelties.items[new Date().getFullYear()] === undefined) {
        y = 2022
        this.store.loadNovelties({ maxYear: y })

      } else {
        y = new Date().getFullYear()
        this.store.loadNovelties({ maxYear: y })

      }
      return y
    },

    loading() {
      return this.store.loading
    }
  },
  watch: {
    "loading"() {
      if (this.loading) {
        let loader = this.$loading.show({
          container: this.$refs.loadingContainer,
          zIndex: 99,
        });
        setTimeout(() => {
          loader.hide()
        }, 1000)
      }
    },
    "menuAnclas"() {
      let element = this.$route.hash.replace('#', '')
      const offsetTop = document.getElementById(this.menuAnclas).offsetTop - 150;
      setTimeout(function () {
        scroll({
          behavior: "smooth",
          top: offsetTop,
        });
      }, 200);
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/general.scss";

.news {
  .title-grand {
    margin-bottom: 25px;
    color: #8baddc5e;
  }

  .menu-anclas {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  #contacto-prensa {
    margin-bottom: 80px;
  }

  #agenda-presidencia {
    padding: 40px 0;
    border-radius: 13px;
    background: $blueSuperLight;

    .column-center {
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 20px;

      .title-decoration-bottom {
        width: 100%;
        text-align: center;
        font-size: 27px;
        font-family: "Frank Ruhl Libre", serif;
        font-weight: 700;

        &::after,
        &::before {
          right: 0;
          left: 0;
          margin: 0 auto;
        }
      }
    }
  }

  #exposiciones,
  #novedades {
    margin: 60px 0;
  }

  #galeria {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
</style>
