<template>
  <section class="expo-room">
    <div class="container event-hor">
      <router-link :to="{name:'exposiciones-vista',
      params:{
        slug: dataContent.slug
      }}" title="Enlace a la exposición" class="d-flex " v-if="dataContent">
        <div class="d-flex-column">
          <img v-if="dataContent.image.url" :src="dataContent.image.url" :alt="dataContent.name" />
          <h2 class="title">{{dataContent.name}}</h2>
          <p >{{dataContent.description}}</p>
          <!-- <div class="d-flex-column box-date">
                      <p class="category-date">
                        <span v-if="dataContent.unpublish_date">Desde</span>
                        {{ currentDateTime(dataContent.publish_date) }}
                        <span v-if="dataContent.unpublish_date">al {{ currentDateTime(dataContent.unpublish_date) }}</span>
                        *Fecha de inicio y fin expo- no de publicación*
                      </p>
                      <p class="category-hour">Lunes, martes, miércoles y jueves: de 8:00 a 15:30 horas Viernes: oficina cerrada *Falta dato- dato estático*
                      </p>
          
                      <p class="category-location">C/Recoletos, 1 bajo 28001 Madrid.*Falta dato- dato estático*</p>
          
                    </div> -->
        </div>

      </router-link>
     
    </div>
  </section>
</template>

<script>
  import moment from 'moment'
  export default {
    name: "ExpoRoom",
    props: ['dataContent'],
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../styles/general";
  @import "../../styles/mixins";

  .expo-room {
    /* padding: 20px 0 40px 0;
    margin: 40px 0 80px 0; */

    a {
      text-decoration: none;
      color: $black;
    }

    .event-hor {
      .grid:first-child {
        padding-right: 40px;
      }

      img {
        width: 100%;
        border-radius: 13px;
        height: 270px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        margin-bottom: 20px;
      }

      .btn-white {
        background: transparent;
        color: $blue;

        &:hover {
          background: $blue;
          color: white;
        }
      }

      .box-date {
        p {
          font-weight: 300;
          padding-left: 30px;
          margin-bottom: 15px;
        }

        .category-location {
          background-size: 18px;
        }
      }
    }
  }

  @include sm {
    .title-grand {
      font-size: 2.5rem !important;
    }

    .grid-2 {
      .grid {
        width: 100%;
        padding: 10px !important;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
          border-radius: 13px;
        }
      }
    }
  }

  @media screen and (max-width:895px) {
    .expo-room {
      margin: 30px 0;
    }
  }
</style>