<template lang="html">

  <section class="banner-cermi-semanal">
    <div class="container">
      <p class="title-grand-primary">CERMI Semanal</p>
      <div class="box-container">
        <p class="subtitle"><a href="http://semanal.cermi.es/lista/default.aspx" target="_blank">cermi.es.semanal,</a> el periódico de la discapacidad</p>
        <a href="http://semanal.cermi.es/lista/default.aspx" target="_blank" class="btn btn-transparent">Acceder</a>
      </div>
    </div>
  </section>

</template>

<script lang="js">

  export default  {
    name: 'banner-cermi-semanal',
    props: [],
    mounted () {

    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
  .banner-cermi-semanal {

  }
</style>
